.admin-product-filter-section {
  background-color: white;
}
.admin-product-search-inputs {
  max-width: 40%;
  position: relative;
}
.admin-product-search-icon {
  /* position: absolute;
    right: 1%; */
  bottom: 8px;
  width: 50px;
  height: 20px;
  z-index: 10;
}

ul {
  padding: 0 !important;
}
.admin-hospital-search-icon {
  position: absolute;
  right: 1%;
  bottom: 0px;
  width: 30px;
  height: 20px;
  z-index: 10;
}
.search__inputBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 100%; */
  margin-left: 15px;
}

.filter-section {
  background-color: white;
}

.modal {
  padding: 0 !important;
}

/* .search-field:focus {
        box-shadow: none !important;
      } */
.admin-product-dropdown-periods {
  width: 200px !important ;
  z-index: 50;
}
.admin-product-row-table-header {
  color: #161616;
  font-family: mediumFont;
  font-size: 16px;
}
.admin-product-dropdown-periods-custom {
  width: 200px !important ;
  z-index: 52;
}
.admin-product-filter-date {
  width: 200px !important;
}
.admin-product-Filter__button {
  background-color: #f8f8f8 !important;
  border: 1px solid #b3cdff !important;
  color: #393939 !important;
}
/* 
  .sectionHeader {
    margin: 0 0 15px 0;
  } */

#search__input {
  position: relative;
  right: 0;
  width: 30px;
  border: none;
  transition: width 240ms cubic-bezier(0.2, 0, 0.38, 0.9),
    background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  background-image: url("../images/loupe.png");
  /* background-size: contain; */
  background-position: 15px 18px;
  background-repeat: no-repeat;
  padding: 14px 20px 14px 40px;
}

.user__radio {
  border-radius: 50%;
  width: 20px !important;
  height: 20px;
}

/* #search__input--focused {
    position: relative;
    border: none;
    transition: width 240ms cubic-bezier(0.2, 0, 0.38, 0.9),
      background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
    width: 100%;
    padding: 14px;
  } */

#search__input:focus {
  position: relative;
  /* left: 0; */
  border: none;
  transition: width 240ms cubic-bezier(0.2, 0, 0.38, 0.9),
    background-color 110ms cubic-bezier(0, 0, 0.38, 0.9);
  width: 100%;
  padding: 14px 20px 14px 50px;
}

#inputDropdown input {
  width: inherit !important;
  outline: none !important;
  font-family: semiFont !important;
  color: #183b56;
}

#inputDropdown input:focus {
  border: none !important;
}
#inputDropdown div div div .css-14dclt2-Input {
  width: 100% !important;
}

.primary__button {
  background-color: #0f61fd !important;
  padding: 15px !important;
  width: 200px;
  border-radius: 0 !important;
  text-transform: initial !important;
}

.floating__content {
  position: fixed;
  bottom: 0 !important;
  background-color: rgba(41, 41, 41, 0.733) !important;
  padding: 10px 15px;
  color: #fff !important;
  font-family: semiFont;
  border-radius: 10px;
}
@media only screen and (max-width: 622px) {
}
.admin-product-row-filter__button {
  background-color: #f8f8f8 !important;
  border: 1px solid #b3cdff !important;
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 10px #00000017 !important;
  color: #000000 !important;
  font-family: mediumFont !important;
}
.admin-product-filter-text {
  color: #393939;
  font-family: mediumFont;
  font-size: 16px;
  margin-right: 20px;
  margin-left: 10px;
}
.admin-product-row-dropdown-status {
  width: 200px !important ;
}
.admin-product-row-filter {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.admin-product-row-hided-col-price {
  display: none;
}

.admin-product_usertable {
  border: "1px solid #B3CDFF " !important;
}

#admin-product-rd p {
  display: none;
}
@media only screen and (min-width: 993px) {
  .admin-product-hided-row {
    display: none !important;
  }
}

@media only screen and (max-width: 440px) {
  .admin-product-row-price-col {
    display: none;
  }
  .admin-product-row-hided-col-price {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  #admin-product-rd p {
    display: inline;
  }
  .admin-product-row-action-col {
    display: none;
  }
  .admin-product-row-status-col {
    display: none;
  }
  .admin-product-row-hided-col-testtype {
    display: none;
  }
}
#admin-product-row .admin-product-row_table-header {
  color: #161616 !important;
  font-family: mediumFont !important;
  font-size: 16px !important;
}
.admin-product-row-table-row-data {
  color: #525252;
  font-family: mediumFont;
  padding: 20px 10px !important;
}

.table td,
.table th {
  border: none !important;
}

tbody tr:hover {
  background-color: rgb(241, 241, 241) !important;
}
/* .table-row-hover:hover {
      } */
/* .toggle-row-icon {
        color: ;
      } */
.admin-product-row-table-hide-list {
  list-style-type: none;
}
.admim__Addproduct--dropdown {
  width: 100%;
}
#a123 .admim__Addproduct--dropdown {
  border: "1px solid red";
}

.admim__Addproduct--dropdown .css-yk16xz-control {
  padding: 10px 10px;
}
.admim__Addproduct--dropdown .css-1pahdxg-control {
  padding: 10px 10px;
}
.admin-product-filter_input-small {
  /*padding: 0.375rem 1.75rem !important;*/
  padding: 18px 10px !important;
}
@media only screen and (max-width: 768px) {
  .admin-product-filter_input-small {
    margin-top: 10px !important;
  }
  .admin-product-row-table-row-data {
    word-wrap: break-word;
  }
}
.admin-billview-container {
  width: 100%;
  background-color: white;
  border: 1px solid #b3cdff;
  padding: 20px;
}
@media only screen and (max-width: 992px) {
  .addproduct__button--align {
    text-align: center !important;
  }
  .addproduct__button--aligncenter {
    justify-content: center !important;
  }
}
.addproduct--input {
  padding: 25px 10px !important;
}

/* .adminuser__country--dropdown:hover {
      outline: none !important;
      box-shadow: none !important;
    } */
.addproduct__error--position p {
  color: #dc3545;
  font-family: mediumFont;
  font-size: 14px;
}
.addproduct__error--position {
  position: absolute;
  right: 10px;
  bottom: -9px;
  padding: 0px 10px;
  background-color: white;
}
.react-select-container {
  background-color: red !important;
}
.adminuser__country--dropdown {
  height: 50px;
}
.addhospital__searchicon--position {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.addhospital__searchicon--position2 {
  position: absolute;
  right: 10px;
  bottom: 8px;
}
.adminhospital--product-error {
  color: red;
  font-size: 14px;
  font-family: mediumFont;
}
.admin--vaccine-productselect .title_text {
  color: #707070;
  font-size: 14px;
  font-family: regularFont;
}
.fixHeader {
  /* border: 1px solid #b3cdff; */
  /* margin-top: 10px; */
  box-shadow: 0px 3px 10px #00000017;
}
.fixHeader tbody {
  display: block;
  /* height: 450px; */
  overflow: auto;
}
.fixHeader thead,
.fixHeader tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.vaccine-product-table td {
  vertical-align: middle !important;
  padding: 20px 10px !important;
}
.vaccine-days {
  margin-bottom: none !important;
}
.vaccineproduct__error--position {
  position: relative;
  text-align: right;
  color: red;
  font-family: mediumFont;
  margin-bottom: 0px;
  margin-top: 5px;
}
.dose-container {
  background-color: white;
  border: 1px solid #b3cdff;
  padding: 20px;
  border-radius: 10px;
}
.dose-label {
  font-family: mediumFont;
  font-size: 20px;
  vertical-align: middle;
}
.dose-separator {
  width: 100%;
  height: 1px;
  background-color: #909090;
}

.dose-content {
  justify-content: center !important;
  vertical-align: middle;
  padding: 20px;
}
.modal-body-container {
  overflow-y: scroll !important;
}
