.test__header {
  background-color: #000107;
}

.test {
  background-color: #fff;
  width: 100%;
  min-height: 89vh;
  border-radius: 6px;
  box-shadow: 0px 4px 12px #a1abc633;
}

.test__content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  color: #fff;
  width: 70%;
  margin: 0 auto;
}
.header__number {
  margin-right: 20px;
}

/* s */

.test__login {
  margin-left: 10px;
  margin-right: 10px;
}

.dropdown-basic {
  color: #fff;
  width: 100px;
}

.dropdown {
  display: flex;
  align-items: center;
}

.test__dropdown--item {
  text-decoration: none;
  color: #000000;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.text__button {
  color: #1565d8 !important;
  padding: 10px 15px !important;
  text-transform: initial !important;
  font-size: 18px !important;
}
.text__button:not(:first-of-type) {
  margin-left: 5px !important;
}

.test__container {
  width: 95%;
  margin: auto;
}

.filter__input {
  padding: 8px;
  border: 1px solid #b3cdff80 !important;
  padding-left: 12px;
  border-radius: 6px;
  font-size: 18px;
  font-family: semiFont;
  color: #565656;
}

.test__titleText .filter__container .filter__input:focus {
  border: 1px solid #b3cdff80 !important;
}

.filter-search-icon {
  position: absolute;
  right: 6px;
  bottom: 11px;
  width: 30px;
  z-index: 10;
}

.test__titleText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: semiFont;
  font-size: 20px;
  margin-top: 30px;
}

.test__packageinfo {
  display: flex;
  align-items: center;
}
.test__title {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  font-family: semiFont;
  font-size: 18px;
}

.test__items {
  border: 1px solid #b3cdff80;
  box-shadow: 0px 4px 12px #e2eaf9;
  padding: 10px;
  margin-top: 10px;
  background-color: white;
  border-radius: 10px;
}

.test__itemHeader {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
}
.test__firstcol {
  display: flex;
  align-items: center;
}

.test__totalAmount {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.test__amount {
  display: flex;
  align-items: center;
  font-family: demiFont;
  font-size: 18px;
}

/* .test__description {
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */

.test__discount {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 18px;
  /* font-family: demiFont; */
}

.oldAmount {
  color: #818181;
  margin-left: 2px;
  font-family: boldFont;
  text-decoration: line-through;
}

.newAmount {
  margin-left: 5px;
  font-family: boldFont;
}

/* input {
    padding: 1px 0 !important;
    border: none !important;
  } */

b {
  background-color: #fff !important;
  border: none !important;
  padding: 5px 0 !important;
}

.inputbox {
  margin-top: 5px;
}

/* .test__texture {
    margin-left: 30px;
  } */

.test__centerTitle {
  display: flex;
  align-items: center;
  font-family: bolfFont;
  font-size: 20px;
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.inputRow {
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  margin-top: 10px;
  /* justify-content: space-between; */
}

.inputDropdown {
  width: 250px;
}

.testcenter {
  margin-top: 10px;
}

.test__checkContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.test__checkoutAmount {
  /* width: 180px; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  /* margin-top: 15px; */
}

.test__summary {
  color: #818181;
  font-size: 15px;
  font-family: boldFont;
}

.test__total {
  display: flex;
  align-items: center;
  color: #818181;
  font-size: 15px;
  font-family: boldFont;
}
.test__checkoutBtn {
  background-color: #0f61fd !important;
  font-family: boldFont !important;
  color: #fff !important;
  margin-top: 5px !important;

  text-transform: initial !important;
}

.test__chekout_disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
  font-family: boldFont !important;
  color: rgba(0, 0, 0, 0.26) !important;
  margin-top: 5px !important;

  text-transform: initial !important;
}

@media only screen and (max-width: 481px) {
  .test__packageinfo {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 570px) {
  .test__centerContainer .inputGroup {
    justify-content: center;
  }
  .test__centerContainer .inputGroup .inputDropdown {
    width: 300px;
    /* margin-left: 15px !important; */
  }
}

@media only screen and (max-width: 766px) {
  .test__titleText {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter__container {
    margin-top: 10px;
  }
}
