.NavHeader {
  margin-top: 20px;

  min-height: 100vh !important;
}

.nav__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo {
  max-width: 200px;
}

.nav__link {
  text-decoration: none;
  font-family: demiFont;
  color: #000000;
}

.signup__link {
  text-decoration: none;
  font-family: demiFont;
  color: #4059e2;
}
.label {
  color: #393939;
  font-family: mediumFont;
  padding-left: 10px;
}
.signup__input {
  padding: 25px 20px;
  background-color: white;
}
.signup__input:hover,
.signup__select:hover {
  outline: none;
  box-shadow: none;
}
.signup__select {
  height: 50px;
}
/* .MuiPaper-root {
  background-color: #f8f8f8 !important;
} */

.agreement__label {
  color: #1f1f1f;
  font-family: mediumFont;
}
.signuppwd__icon {
  position: absolute;
  right: 3%;
  top: 52.5%;
}
.signup__button {
  /* background-color: #4059e2 !important; */
  width: 150px !important;
  text-transform: initial !important;
  font-family: boldFont;
  padding: 10px 15px !important;
  font-size: 16px !important;
}
.error {
  color: red;
  font-family: mediumFont;
  font-size: 14px;
}
.errorPosition {
  position: absolute;
  bottom: -8px;
  right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /* background: linear-gradient(#ffffff, #f8f8f8); */
  background: #fff;
  color: #cc0000;
  padding: 0 5px;
  margin: 0;
  font-size: 14px;
}
.errorPosition2 {
  position: absolute;
  bottom: 8px;
  right: 20px;
  /* background: linear-gradient(#ffffff, #f8f8f8); */
  background: #fff;
  color: #cc0000;
  padding: 0 5px;
  margin: 0;
  font-size: 14px;
}
.verification__header--text {
  color: #616161;
  /* font-family: mediumFont; */
  font-size: 18px;
}

.MuiStepIcon-root.MuiStepIcon-active,
.MuiStepIcon-root.MuiStepIcon-completed {
  color: #1565d8 !important;
}
@media only screen and (max-width: 420px) {
  .custom-col-400 {
    width: 90% !important;
    max-width: none;
    flex: none;
  }
}

@media only screen and (max-width: 370px) {
  .custom-col-400 {
    width: 100% !important;
    max-width: none;
    flex: none;
  }
}

@media only screen and (max-width: 491px) {
  .custom-verifi--header--text {
    width: 95% !important;
    max-width: none;
    flex: none;
  }
}

@media only screen and (max-width: 439px) {
  .custom-verifi--header--text {
    width: 100% !important;
    max-width: none;
    flex: none;
  }
}

@media only screen and (max-width: 380px) {
  .custom-verifi--input {
    width: 100% !important;
    max-width: none;
    flex: none;
  }
}
@media only screen and (max-width: 307px) {
  .custom-verifi--input {
    width: 100% !important;
    max-width: none;
    flex: none;
  }
}
.MuiStepper-root {
  padding: 20px 0px !important;
}
.MuiStep-horizontal {
  padding: 0 !important;
}
@media only screen and (max-width: 480px) {
  .custom-personal--input {
    width: 95% !important;
    max-width: none;
    flex: none;
  }
}

@media only screen and (max-width: 333px) {
  .custom-personal--input {
    width: 100% !important;
    max-width: none;
    flex: none;
  }
  .errorPosition {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .errorPosition2 {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 361px) {
  .label {
    padding-left: 15px;
    text-align: center;
  }
}
@media only screen and (max-width: 576px) {
  .last-column {
    justify-content: center !important;
  }
}
