.ub-filter-section {
  background-color: white;
}
.search-inputs {
  max-width: 40%;
  position: relative;
}
.search-icon {
  position: absolute;
  right: 1%;
  bottom: 10px;
  width: 30px;
  height: 20px;
  z-index: 10;
}

.bills__row {
  display: flex;
  /* justify-content: space-between; */
}

.bills__address {
  display: flex;
  flex-direction: row;
}

.table__header {
  border-bottom: 1px solid #b3cdff !important;
}

.ub-dropdown-periods {
  width: 200px !important ;
  z-index: 50 !important;
}
.ub-table-header {
  color: #818181;
  font-family: mediumFont;
  font-size: 14px;
}
.dropdown-periods-custom {
  width: 200px !important ;
  z-index: 52;
}
.filter-date {
  width: 200px !important;
}
.Filter__button {
  background-color: #f8f8f8 !important;
  border: 1px solid #b3cdff !important;
}

.ub-filter__button {
  background-color: #f8f8f8 !important;
  border: 1px solid #b3cdff !important;
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 10px #00000017 !important;
  color: #000000 !important;
  font-family: mediumFont !important;
}
.filter-text {
  color: #393939;
  font-family: mediumFont;
  font-size: 16px;
  margin-right: 20px;
  margin-left: 10px;
}
.ub-dropdown-status {
  width: 200px !important ;
}
.row-filter {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.ub-hided-col-price {
  display: none;
}

.ub_usertable {
  border: "1px solid #B3CDFF " !important;
}

#rd p {
  display: none;
}

#bill-row .ub_table-header {
  color: #818181 !important;
  font-family: mediumFont !important;
  font-size: 14px !important;
}
.ub-table-row-data {
  color: #000000;
  font-family: mediumFont;
  padding: 20px 10px !important;
}

.ub-table-hide-list {
  list-style-type: none;
}

.admin-billview-container {
  width: 100%;
  background-color: white;
  border: 1px solid #b3cdff;
  padding: 20px;
  min-height: 90vh;
}

.bills__options {
  display: flex;
  align-items: center;
}

.bills__containerHeader {
  display: flex;
}
/* .css-2613qy-menu {
    position: relative;
    z-index: 9999 !important;
  } */
.animate__slideInUp {
  z-index: 999 !important;
}
.fixHeader .table {
  border-color: #b3cdff !important;
  border-spacing: 10px !important;
}
.fixHeader .table td,
.fixHeader .table th {
  border-top: none !important;
}
.fixHeader .table thead th {
  border-color: #b3cdff !important ;
  border-width: 1px !important;
}
.filter-section {
  border: 1px solid #e0e0e0;
}

/* invoice-view styles */
.pdf__container {
  width: 95%;
  margin: 2.5% auto;
  /* height: 80vh; */
}

.pdf__header,
.pdf__content {
  /* width: 95%; */
  display: flex;
  justify-content: space-between;
}

.pdf__header {
  padding-bottom: 20px;
  border-bottom: 2px solid #1565d8;
}

.pdf__address,
.pdf__address2 {
  text-align: right;
}

.pdf__labname {
  text-decoration: underline;
  text-decoration-color: #1565d8;
  color: #1565d8;
  letter-spacing: 1.15;
  font-size: 30px;
}

.invoice__title {
  letter-spacing: 1.15;
  font-size: 30px;
}

.pdf__table {
  width: 100%;
  /* overflow: auto; */
  margin-top: 20px;
}

.table__content {
  text-align: center;
  padding: 10px;
}

.pdf__table thead {
  background: #b4bbc1;
}

.pdf__table--footer {
  border-top: 1px solid #bdbcbc;
}

.width-10 {
  width: 10%;
}

.width-20 {
  width: 20%;
}

.width-40 {
  width: 40%;
}

.pdf__hr {
  border-top: 0px;
  border-bottom: 2px solid #1565d8;
  height: 2px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .ub-dropdown-periods {
    width: 230px !important ;
    z-index: 50 !important;
  }
  .filter-icon-position {
    /* width: 100%; */

    text-align: left;
  }
}

@media only screen and (min-width: 993px) {
  .ub-hided-row {
    display: none !important;
  }
}

@media only screen and (max-width: 525px) {
  .pdf__header,
  .pdf__content {
    flex-direction: column;
  }
  .pdf__address,
  .pdf__address2 {
    text-align: left;
    margin-top: 10px;
  }

  .pdf__address {
    padding-left: 5px;
  }
  .table__content {
    padding: 5px;
  }
}

@media only screen and (max-width: 440px) {
  .ub-price-col {
    display: none;
  }
  .ub-hided-col-price {
    display: block !important;
  }
}

@media only screen and (max-width: 992px) {
  #rd p {
    display: inline;
  }
  .ub-action-col {
    display: none;
  }
  .ub-status-col {
    display: none;
  }
  .ub-hided-col-testtype {
    display: none;
  }
}

@media only screen and (max-width: 380px) {
  .bills__containerHeader {
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .ub-filter_input-small {
    margin-top: 10px !important;
  }

  .bills__address {
    display: flex;
    flex-direction: column;
  }

  .bill__address1 {
    width: 100% !important;
  }
}
